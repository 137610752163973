import React from "react";
import { graphql } from "gatsby";
import Layout from "../styles/layout";
import ArticleGridItem from "../components/articleGridItem";
import AuthorAndSocialMediaComponent from "../components/authorAndSocialMediaComponent";
import { Box, Grid, Typography, Container } from "@mui/material";
import TagsComponent from "../components/tagsComponent";
import Seo from "../components/seo";
import AffiliateView from "../components/affiliateView";
import { styled } from "@mui/material/styles";

const TitleButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  marginTop: "16px",
}));

export default function Tag(props) {
  const { data, location, pageContext } = props;
  const { tag } = pageContext;
  const articles = data.articles.nodes;
  const tags = data.tags.group.map((tag) => tag.fieldValue);

  return (
    <Layout location={location}>
      <Seo
        title="Articles about Swift and iOS development"
        description={"Discover articles and tips tagged with " + tag}
        robots="noindex"
      />
      <Container>
        <Typography variant="h1">
          Articles about Swift and iOS development
        </Typography>
        <Box mt={2}></Box>
        <AuthorAndSocialMediaComponent></AuthorAndSocialMediaComponent>

        <TitleButtonContainer>
          <Typography variant="h2">
            Articles and tips tagged with {tag}
          </Typography>
        </TitleButtonContainer>

        <Grid container spacing={1}>
          {articles.map((node) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={node.id}>
                <ArticleGridItem article={node}></ArticleGridItem>
              </Grid>
            );
          })}
        </Grid>

        <TitleButtonContainer>
          <Typography variant="h2">All tags</Typography>
        </TitleButtonContainer>
        <Box height="12px"></Box>
        <TagsComponent tags={tags} isLargerSize="true"></TagsComponent>
        <AffiliateView type="sponsor"></AffiliateView>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($tag: String) {
    articles: allMdx(
      filter: { frontmatter: { tags: { eq: $tag } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 200
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date(formatString: "DD MMM YYYY")
          tags
          readingTime
        }
        slug
        id
      }
    }

    tags: allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
